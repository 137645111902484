<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.team')"
        :pgIcon="'bx bx-flag'"
        :refs="refs"
        :addNew="addNew"
      >
      </Breadcrumb>

      <DataTable
        :refs="refs"
        :colspan="5"
        :hasSearchFilter="true"
        :hasDateFilter="true"
        :hasStatusFilter="true"
        :hasHashId="true"
        :hasMultiSelect="false"
        :hasBulkAction="true"
        :hasExport="true"
        :addNew="addNew"
        :hasShowEntries="true"
        :lblSearch="$t('app.search')"
        :fields="{
          id: 'id',
          title: 'title',
          date: 'date',
          status: 'status',
        }"
        :hasImage="true"
        :image="$t('view.image')"
        :image_style="'width: 40px;height: 40px;border-radius: 50%'"
        :hasTitle="true"
        :title="$t('view.client_name')"
        :hasDate="true"
        :hasStatus="true"
        :hasActions="true"
        :hasDeleteButton="true"
        :actionable="true"
        :sorDataTable="true"
        v-on:hasAddNewPermission="handlePermissoinChange"
      >
      </DataTable>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DataTable from "@/components/DataTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "List",
  components: {
    DataTable,
  },
  data() {
    return {
      //
      addNew: false,
      refs: "clients",
    };
  },
  created() {
    setCurrentPageTitle(this.$t("nav.clients"), "bx bx-star");
  },
  methods: {
    handlePermissoinChange(event) {
      this.addNew = event;
    },
  },
});
</script>
